.bg-circle-left,
.bg-circle-right {
  width: 15rem;
  height: 15rem;
  background: linear-gradient(to bottom right, #6f828a, #282f32);
  box-shadow: -10px -10px 15px #6f828a, 10px 10px 15px #282f32;

  border-radius: 50%;
  position: absolute;
}


.window-container {
  box-shadow: 0 0 10px #6EE7B7;
  max-width: 480px;
 
}

