.logo-font {
  font-family: "Bona Nova SC", serif;
  font-weight: 400;
  font-style: normal;
}

.ToggleGroupItemA[data-state='on'] {
  background-color: #14b8a6; /* You can replace this with your Tailwind color */

}
.ToggleGroupItemB[data-state='on'] {
  background-color: #f43f5e; /* You can replace this with your Tailwind color */

}
