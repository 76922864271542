

@keyframes left-to-right {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes right-to-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-10%);
    opacity: 0;
  }
}

/* .popOverContent {
  display: none;
} */

.popOverContent[data-state="closed"] {
  
  animation: right-to-left 150ms ease-in-out;
  /* display: none; */
}
.popOverContent[data-state="open"] {
  /* display: block; */
  animation: left-to-right 150ms ease-in-out;
  
}
