.appearUp{
    animation: appearUp 0.5s ease-in-out;
    
}

@keyframes appearUp {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
    
}